import React, { useRef, useState, useEffect } from "react";

const Test = ({ topics }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsVisible(false);
      }
    };
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

//   useEffect(() => {
//     if document.getElementsByClassName("none") {
//         setIsVisible(true);
//     }
//   }, []);
    
  return (
    <div>
      <section className="section">
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsVisible(!isVisible);
          }}
        >
          toggle modal
        </button>
        <div
          ref={ref}
          className="modal"
          style={{ display: isVisible ? "none" : "block" }}
        />
      </section>
    </div>
  );
}


export default Test;
