import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Loading from "../../../../images/spinner.gif";

const LazyCourses = ({ topics }) => {
  //lazy load API data 
  const [displayCount, setDisplayCount] = useState(15);
  const [loading, setLoading] = useState(false);

  const loadMore = () => {
    setLoading(true);
    setDisplayCount((prevCount) => prevCount + 15);
  };

  const handleScroll = () => {
    const isScrolledToBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 50;

    if (isScrolledToBottom && !loading) {
      loadMore();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [displayCount]);

  // console.log("LazyCourses loaded lazily!");

  const [query, setQuery] = useState("");
  const [spanValue, setSpanValue] = useState("");
  const [onlinevalue, setOnlinevalue] = useState("");
  const [kitvalue, setKitvalue] = useState("");
  const [tttvalue, setTTTvalue] = useState("");
  const [videovalue, setVideovalue] = useState("");
  const [preopvalue, setPreopvalue] = useState("");
  const [oshavalue, setOshavalue] = useState("");
  const [caloshavalue, setCaloshavalue] = useState("");
  const [canadavalue, setCanadavalue] = useState("");
  const [yearvalue, setYearvalue] = useState("");
  const [lastyearvalue, setLastyearvalue] = useState("");
  const [monthvalue, setmonthvalue] = useState("");
  const [lastmonthvalue, setLastmonthvalue] = useState("");
  const [cheapValue, setCheapValue] = useState("");
  const [expenseValue, setExpenseValue] = useState("");


  const [isShownCourseType, setisShownCourseType] = useState(true);
  const [isShownLanguage, setisShownLanguage] = useState(true);
  const [isShownStandards, setisShownStandards] = useState(true);
  const [isShownPrice, setisShownPrice] = useState(true);
  const [isShownDate, setisShownDate] = useState(true);

  //filter toggle visbility
  const handleClickCourseType = (event) => {
    setisShownCourseType((current) => !current);
  };
  const handleClickLanguage = (event) => {
    setisShownLanguage((current) => !current);
  };
  const handleClickStandards = (event) => {
    setisShownStandards((current) => !current);
  };
  const handleClickDate = (event) => {
    setisShownDate((current) => !current);
  };
  const handleClickPrice = (event) => {
    setisShownPrice((current) => !current);
  };

  //course type filter
  function onlineFunction(e) {
    if (document.getElementById("onlineId").checked) {
      document.getElementById("onlineId").value = "Y";
      setOnlinevalue(e.target.value);
    } else {
      document.getElementById("onlineId").value = "";
      setOnlinevalue(e.target.value);
    }
  }
  function kitFunction(e) {
    if (document.getElementById("kitId").checked) {
      document.getElementById("kitId").value = "Y";
      setKitvalue(e.target.value);
    } else {
      document.getElementById("kitId").value = "";
      setKitvalue(e.target.value);
    }
  }
  function tttFunction(e) {
    if (document.getElementById("tttId").checked) {
      document.getElementById("tttId").value = "Y";
      setTTTvalue(e.target.value);
    } else {
      document.getElementById("tttId").value = "";
      setTTTvalue(e.target.value);
    }
  }
  function videoFunction(e) {
    if (document.getElementById("trainingVideoId").checked) {
      document.getElementById("trainingVideoId").value = "Y";
      setVideovalue(e.target.value);
    } else {
      document.getElementById("trainingVideoId").value = "";
      setVideovalue(e.target.value);
    }
  }
  function preOpFunction(e) {
    if (document.getElementById("preOpId").checked) {
      document.getElementById("preOpId").value = "Y";
      setPreopvalue(e.target.value);
    } else {
      document.getElementById("preOpId").value = "";
      setPreopvalue(e.target.value);
    }
  }

  //language filter
  function englishFunction(e) {
    if (document.getElementById("englishId").checked) {
      document.getElementById("englishId").value = "";
      setSpanValue(e.target.value);
    } else {
      document.getElementById("englishId").value = "";
      setSpanValue(e.target.value);
    }
  }
  function spanishFunction(e) {
    if (document.getElementById("spanishId").checked) {
      document.getElementById("spanishId").value = "Y";
      setSpanValue(e.target.value);
    } else {
      document.getElementById("spanishId").value = "";
      setSpanValue(e.target.value);
    }
  }

  //standards filter
  function oshaFunction(e) {
    if (document.getElementById("oshaId").checked) {
      document.getElementById("oshaId").value = "Y";
      setOshavalue(e.target.value);
    } else {
      document.getElementById("oshaId").value = "";
      setOshavalue(e.target.value);
    }
  }
  function calOshaFunction(e) {
    if (document.getElementById("calOshaId").checked) {
      document.getElementById("calOshaId").value = "Y";
      setCaloshavalue(e.target.value);
    } else {
      document.getElementById("calOshaId").value = "";
      setCaloshavalue(e.target.value);
    }
  }
  function canadaFunction(e) {
    if (document.getElementById("canadaId").checked) {
      document.getElementById("canadaId").value = "Y";
      setCanadavalue(e.target.value);
    } else {
      document.getElementById("canadaId").value = "";
      setCanadavalue(e.target.value);
    }
  }
  //filter price
  function cheapPriceFunction(e) {
    if (document.getElementById("cheapId").checked) {
      document.getElementById("cheapId").value = "39 USD";
      setCheapValue(e.target.value);
    } else {
      document.getElementById("cheapId").value = "";
      setCheapValue(e.target.value);
    }
  }
  function expensePriceFunction(e) {
    if (document.getElementById("expenseId").checked) {
      document.getElementById("expenseId").value = "79 USD";
      setExpenseValue(e.target.value);
    } else {
      document.getElementById("expenseId").value = "";
      setExpenseValue(e.target.value);
    }
  }

  //date filter
  let year = new Date().getFullYear();
  function yearFunction(e) {
    if (document.getElementById("yearId").checked) {
      document.getElementById("yearId").value = year;
      setYearvalue(e.target.value);
    } else {
      document.getElementById("yearId").value = "";
      setYearvalue(e.target.value);
    }
  }
  let lastYear = year - 1;
  // console.log(lastYear);
  toString(lastYear);
  function lastYearFunction(e) {
    if (document.getElementById("lastYearId").checked) {
      document.getElementById("lastYearId").value = lastYear;
      setLastyearvalue(e.target.value);
    } else {
      document.getElementById("lastYearId").value = "";
      setLastyearvalue(e.target.value);
    }
  }

  const date = new Date();
  let months = date.getMonth() + 1;
  months = months <= 10 ? "0" + months : months;
  const thisMonth = year + "-" + months;
  // console.log(thisMonth);
  function monthFunction(e) {
    if (document.getElementById("monthId").checked) {
      document.getElementById("monthId").value = thisMonth;
      setmonthvalue(e.target.value);
    } else {
      document.getElementById("monthId").value = "";
      setmonthvalue(e.target.value);
    }
    // console.log(setmonthvalue)
  }

  let lastMonth = months <= 1 ? "12" : months - 1;
  lastMonth = lastMonth <= 10 ? "0" + lastMonth : lastMonth;
  const thisLastMonth = year + "-" + lastMonth;
  // console.log(thisLastMonth);
  function lastMonthFunction(e) {
    if (document.getElementById("lastMonthId").checked) {
      document.getElementById("lastMonthId").value = thisLastMonth;
      setLastmonthvalue(e.target.value);
    } else {
      document.getElementById("lastMonthId").value = "";
      setLastmonthvalue(e.target.value);
    }
    // console.log(setLastmonthvalue)
  }
  
  return (
    <div>
      <section>
        <div className="masterBanner__banner-container">
          <h1>Course Catalog</h1>
          <h2>THE MASTER LIST</h2>
          <input
            type="text"
            placeholder="Search..."
            className="masterList__search"
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </section>
      <section className="section"></section>

      <section className="section masterList__filter-course-grid">
        <div className="filter__section">
          <div className="filter__remove-padding">
            {/* <h3>Course Filters</h3>
            <br></br> */}
            {/* <div className="filter-black-color-block"></div> */}
            <div className="filter__filters-flex-box">
              <div className="filter__padding-top-nthchild">
                <button
                  type="button"
                  className="filter__button-filter"
                  onClick={handleClickCourseType}
                >
                  Course Type:
                </button>
                <div
                  style={{ display: isShownCourseType ? "block" : "none" }}
                  className="filter__filter-container"
                >
                  <div className="filter__input-div">
                    <input
                      id="onlineId"
                      onChange={onlineFunction}
                      type="checkbox"
                    />
                    <label htmlFor="onlineId">&nbsp; &nbsp;Online</label>
                  </div>

                  <div className="filter__input-div">
                    <input id="kitId" onChange={kitFunction} type="checkbox" />
                    <label htmlFor="kitId">&nbsp; &nbsp;DIY Kits</label>
                  </div>

                  <div className="filter__input-div">
                    <input id="tttId" onChange={tttFunction} type="checkbox" />
                    <label htmlFor="tttId">
                      &nbsp; &nbsp;Train the Trainer
                    </label>
                  </div>
                  <div className="filter__input-div">
                    <input
                      id="trainingVideoId"
                      onChange={videoFunction}
                      type="checkbox"
                    />
                    <label htmlFor="trainingVideoId">
                      &nbsp; &nbsp;Training Video
                    </label>
                  </div>
                  <div className="filter__input-div">
                    <input
                      id="preOpId"
                      onChange={preOpFunction}
                      type="checkbox"
                    />
                    <label htmlFor="preOpId">&nbsp; &nbsp;Pre-Op Video</label>
                  </div>
                </div>
              </div>
              <div className="filter__padding-top-nthchild">
                <button
                  type="button"
                  className="filter__button-filter"
                  onClick={handleClickLanguage}
                >
                  Language:
                </button>
                <div
                  style={{ display: isShownLanguage ? "block" : "none" }}
                  className="filter__filter-container"
                >
                  <div className="filter__input-div">
                    <input
                      id="englishId"
                      onChange={englishFunction}
                      type="checkbox"
                    />
                    <label htmlFor="englishId">&nbsp; &nbsp;English</label>
                  </div>
                  <div className="filter__input-div">
                    <input
                      id="spanishId"
                      onChange={spanishFunction}
                      type="checkbox"
                    />
                    <label htmlFor="spanishId">
                      <div className="course__icon-container">
                        <span>
                        &nbsp; &nbsp;Spanish
                        </span>
                        <div className="course__icon filter__icon">
                          &#x1F6C8;
                          <span className="course__icon-hide filter__icon-hide">
                            As of now, only OSHA courses are available in Spanish. <br></br><br></br>Filter results will display any course that has a Spanish version. For example, if you checkmark the CAL-OSHA box and Spanish box, the result will be any course that has a Spanish version <i>and</i> CAL-OSHA version--not any courses in Spanish that meet the CAL-OSHA standards. 
                          </span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="filter__padding-top-nthchild">
                <button
                  type="button"
                  className="filter__button-filter"
                  onClick={handleClickStandards}
                >
                  Standards:
                </button>
                <div
                  style={{ display: isShownStandards ? "block" : "none" }}
                  className="filter__filter-container"
                >
                  <div className="filter__input-div">
                    <input
                      id="oshaId"
                      onChange={oshaFunction}
                      type="checkbox"
                    />
                    <label htmlFor="oshaId">&nbsp; &nbsp;OSHA</label>
                  </div>
                  <div className="filter__input-div">
                    <input
                      id="calOshaId"
                      onChange={calOshaFunction}
                      type="checkbox"
                    />
                    <label htmlFor="calOshaId">
                      <div className="course__icon-container">
                        <span>
                        &nbsp; &nbsp;CAL-OSHA
                        </span>
                        <div className="course__icon filter__icon">
                          &#x1F6C8;
                          <span className="course__icon-hide filter__icon-hide">
                            Filter results will display any course that meets both parameters. For example, if you checkmark the CAL-OSHA box and Spanish box, the result will be any course that has a Spanish version and CAL-OSHA version--not any courses in Spanish that meet the CAL-OSHA standards. 
                          </span>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="filter__input-div">
                    <input
                      id="canadaId"
                      onChange={canadaFunction}
                      type="checkbox"
                    />
                    <label htmlFor="canadaId">
                    <div className="course__icon-container">
                        <span>
                        &nbsp; &nbsp;Canada
                        </span>
                        <div className="course__icon filter__icon">
                          &#x1F6C8;
                          <span className="course__icon-hide filter__icon-hide">
                            Filter results will display any course that meets both parameters. For example, if you checkmark the Canada box and Spanish box, the result will be any course that has a Spanish version and Canada version--not any courses in Spanish that meet the Canada standards. 
                          </span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="filter__padding-top-nthchild">
                <button
                  type="button"
                  className="filter__button-filter"
                  onClick={handleClickPrice}
                >
                  Price:
                </button>
                <div
                  style={{ display: isShownPrice ? "block" : "none" }}
                  className="filter__filter-container"
                >
                  <div className="filter__input-div">
                    <input
                      id="cheapId"
                      onChange={cheapPriceFunction}
                      type="checkbox"
                    />
                    <label htmlFor="cheapId">&nbsp; &nbsp;$39 USD</label>
                  </div>
                  <div className="filter__input-div">
                    <input
                      id="expenseId"
                      onChange={expensePriceFunction}
                      type="checkbox"
                    />
                    <label htmlFor="expenseId">
                      <div className="course__icon-container">
                        <span>
                        &nbsp; &nbsp;$79 USD
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="filter__padding-top-nthchild">
                <button
                  type="button"
                  className="filter__button-filter"
                  onClick={handleClickDate}
                >
                  Creation Date:
                </button>
                <div
                  style={{ display: isShownDate ? "block" : "none" }}
                  className="filter__filter-container"
                >
                  <div className="filter__input-div">
                    <input
                      id="monthId"
                      onChange={monthFunction}
                      type="checkbox"
                    />
                    <label htmlFor="monthId">&nbsp; &nbsp;This Month</label>
                  </div>
                  <div className="filter__input-div">
                    <input
                      id="lastMonthId"
                      onChange={lastMonthFunction}
                      type="checkbox"
                    />
                    <label htmlFor="lastMonthId">&nbsp; &nbsp;Last Month</label>
                  </div>
                  <div className="filter__input-div">
                    <input
                      id="yearId"
                      onChange={yearFunction}
                      type="checkbox"
                    />
                    <label htmlFor="yearId">&nbsp; &nbsp;This Year</label>
                  </div>
                  <div className="filter__input-div">
                    <input
                      id="lastYearId"
                      onChange={lastYearFunction}
                      type="checkbox"
                    />
                    <label htmlFor="lastYearId">&nbsp; &nbsp;Last Year</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="masterList__section-flexbox">
          {!topics.length ? (
            <img
              src={Loading}
              className="masterList__spinner"
              alt="Loading..."
            />
          ) : (
            topics .filter(
              (course) =>
                course.OLCreationDate.includes(yearvalue) &&
                course.OLCreationDate.includes(lastyearvalue) &&
                course.OLCreationDate.includes(lastmonthvalue) &&
                course.OLCreationDate.includes(monthvalue) &&
                course.OLCourseTypeCan.includes(canadavalue) &&
                course.OLCourseTypeCal.includes(caloshavalue) &&
                course.OLCourseTypeOsha.includes(oshavalue) &&
                course.PreOpCourseTypeOsha.includes(preopvalue) &&
                course.VideoCourseTypeOsha.includes(videovalue) &&
                course.TTTCourseTypeOsha.includes(tttvalue) &&
                course.KitCourseTypeOsha.includes(kitvalue) &&
                course.OLCourseTypeOsha.includes(onlinevalue) &&
                course.OLCourseTypeSpan.includes(spanValue) &&
                course.OLUSPrice.includes(cheapValue) &&
                course.OLUSPrice.includes(expenseValue) &&


                (course.CourseName.toLowerCase().includes(
                  query.toLowerCase()
                ) ||
                  course.AlternativeNames.toLowerCase().includes(
                    query.toLowerCase()
                  ))
            )
            .sort((a, b) => a.CourseName.localeCompare(b.CourseName))
              .slice(0, displayCount) // slice the array based on displayCount
              .map((course, key) => (
                <div className="masterList__courses-container" key={key}>
                  <Link
                    to={{
                      pathname: `/courses/${course._id}`,
                    }}
                    className="masterList__courses-decoration-none"
                  >
                    <h2
                      className="masterList__container-padding"
                      key={course.CourseName}
                    >
                      {course.CourseName}
                    </h2>
                    <div className="masterList__div-color-block"></div>
                    <div
                      className="masterList__catagory-box"
                      id="AlternativeNames"
                    >
                      <h3>Alternative Names:</h3>
                      <p>
                        {course.AlternativeNames === "N/A"
                          ? "No Alternative Names"
                          : course.AlternativeNames}
                      </p>
                    </div>
                    <div className="masterList__catagory-box">
                      <h3>Course Types:</h3>
                      <p>
                        {course.OLCourseTypeOsha === "Y" ? "Online" : ""}
                        {course.KitCourseTypeOsha === "Y" ? ", Kit" : ""}
                        {course.TTTCourseTypeOsha === "Y"
                          ? ", Train the Trainer"
                          : ""}
                        {course.VideoCourseTypeOsha === "Y"
                          ? ", Training Video "
                          : ""}
                        {course.PreOpCourseTypeOsha === "Y"
                          ? ", Pre-Op Video "
                          : ""}
                      </p>
                    </div>
                    <div className="masterList__catagory-box">
                      <h3>Language:</h3>
                      <p>
                        <span>
                          {course.OLCourseTypeOsha === "Y" ? "English" : ""}
                        </span>
                        <span>
                          {course.OLCourseTypeSpan === "Y" ? ", Spanish " : ""}
                        </span>
                      </p>
                    </div>
                    <div className="masterList__catagory-box">
                      <h3>Standards:</h3>
                      <p>
                        {course.OLCourseTypeOsha === "Y" ? "OSHA" : ""}
                        {course.OLCourseTypeCal === "Y" ? ", CAL-OSHA " : ""}
                        {course.OLCourseTypeCan === "Y" ? ", Canada " : ""}
                      </p>
                    </div>
                  </Link>
                </div>
              ))
          )}
        </div>
        {loading && (
            <img
              src={Loading}
              className="masterList__spinner"
              alt="Loading..."
            />
        )}
      </section>

      <section className="section"></section>
    </div>
  );
};

export default LazyCourses;
