import React, {useEffect} from "react";
import Catalog from "../../../images/course-catalog-icon.png";
import Portal from "../../../images/employee-portal-icon.png";
import Links from "../../../images/helpful-links-icon.png";


function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
        <section className="home__section section">
          <div className="home__flex-box">

            <a href="/courses" className="home__flex-child">
              <img src={Catalog} alt="course catalog icon" className="home__icons" width="100px" height="100px"/>
              <h2>Course Catalog</h2>
              <p>Find the formats, prices, and other details of each training course we have available. Filter by course topic, compare safety standards, and more. </p>
            </a>
            
            <a href="/helpful-links" className="home__flex-child">
              <img src={Links} alt="helpful links icon" className="home__icons" width="100px" height="100px"/>
              <h2>Helpful Links</h2>
              <p>Get connected to your time card, pay stub, insurance portal, and the company help center. All of your helpful links in one place.</p>
            </a>
            <a href="/employee-portal" className="home__flex-child">
              <img src={Portal} alt="Employee Portal icon" className="home__icons" width="100px" height="100px"/>
              <h2>Employee Portal</h2>
              <p>Coming Soon!</p>
            </a>

          </div>
        </section>
    );
}

export default Home;