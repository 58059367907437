//front end is deployed at https://app.netlify.com/teams/tishaparkinson/overview with manual uploads
import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

// import Routes from './services/routes';

import Header from "./ui/static/header/Header";
import Home from "./ui/views/home/Home";
import MasterList from "./ui/views/masterList/MasterList";
import HelpfulLinks from "./ui/views/helpfulLinks/HelpfulLinks";
import EmployeePortal from "./ui/views/employeePortal/EmployeePortal";
import Test from './ui/views/masterList/childComp/Test';
import ScrollToTop from './services/scrollToTop';
// import Course from "./ui/views/masterListCourse/MasterListCourse"


import './App.scss';

function App() {

  return (
    <>
        <Router>
          <ScrollToTop>
            <Header />
          {/* <Route path="/" render={() => <MasterList topics={topics} />} /> */}

        <Route path="/" exact={true}>
            <Home />
          </Route>
          <Route path="/courses">
            <MasterList />
          </Route>
          <Route path="/helpful-links">
            <HelpfulLinks />
          </Route>
          <Route  path="/employee-portal">
            <EmployeePortal />
          </Route>
          <Route path="/test">
            <Test />
          </Route>

          {/* <Route  path="/courses">
            <Course />
          </Route> */}
          </ScrollToTop>
          

        </Router>
    </>
  );
}

export default App;
