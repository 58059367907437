import React, { useState } from "react";
import Nav from "../nav/Nav";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.png";
function Header() {
  const [toggle, setToggle] = useState(false);

  function toggleNav() {
    setToggle(!toggle);
  }

  return (
    <header className={`header ${toggle ? "fixed" : ""}`}>
        <div className="header__container">
          <Link to="/" className="header__logo">
            <img
              src={logo}
              className="header__logo-icon"
              alt="Safety Provisions logo"
            />
          </Link>
          <div
            className={`header__toggle ${toggle ? "open" : ""}`}
            onClick={toggleNav}
          >
            <span className="header__toggle-bars" />
          </div>
        </div>
        <Nav toggle={toggle} toggleNav={toggleNav}></Nav>
    </header>
  );
}

export default Header;
