import React from "react";

function HelpfulLinks() {
    
    return (
        <section className="links__section section">
          <h1>Helpful Links</h1>
          <hr />
          <div className="links__div-link-container">
            <a href="https://tsheets.intuit.com/#w_timecard" target="_blank" rel="noopener noreferrer" className="link">Quick Books Time Card</a>
            <a href="https://workforce.intuit.com/app/payroll-employee-portal-ui/ius/sign-in" target="_blank" rel="noopener noreferrer" className="link">Quick Books Pay Stub</a>
            <a href="https://www.employeenavigator.com/identity/Account/Login?ReturnUrl=%2Fidentity%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dbenefits-6bf92b19-25fc-449a-b13e-37c1686ecb34%26response_type%3Dcode%26scope%3Dopenid%2520email%26redirect_uri%3Dhttps%253A%252F%252Fwww.employeenavigator.com%252Fbenefits%252FAccount%252FHandle%252FSignInOidc%26state%3D4802a33d-9d36-49ee-bf45-f3d8a0b21b23%26nonce%3D3975af05-caaa-4685-b491-1755105f0d1f%26response_mode%3Dform_post" target="_blank" rel="noopener noreferrer" className="link">Insurance Portal</a>
            <a href="https://safetyprovisions.zendesk.com/hc/en-us" target="_blank" rel="noopener noreferrer" className="link">Help Center</a>
          </div>
        </section>
    );
}

export default HelpfulLinks;