import React from "react";
import { Link } from 'react-router-dom';

// import login from "../../../images/login.png";

function Nav({ toggle, toggleNav }) {
  return (
    <nav className={`nav ${toggle ? "open" : ""}`}>
      <ul className="nav__list">
        
        <li className="nav__item">
          <Link
            // exact
            to="/"
            className="nav__link"
            activeclassname="selected"
            onClick={toggleNav}
          >
            Home
          </Link>
        </li>

        <li className="nav__item">
          <Link
            // exact={true}
            to="/courses"
            className="nav__link"
            activeclassname="selected"
            onClick={toggleNav}
          >
            Course Catalog
          </Link>
        </li>

        <li className="nav__item">
          <Link
            // exact={true}
            to="/helpful-links"
            className="nav__link"
            activeclassname="selected"
            onClick={toggleNav}
          >
            Helpful Links
          </Link>
        </li>
        <li className="nav__item">
          <Link
            // exact={true}
            to="/employee-portal"
            className="nav__link"
            activeclassname="selected"
            onClick={toggleNav}
          >
            Employee Portal
          </Link>
        </li>
        
      </ul>
    </nav>
  );
}

export default Nav;
