import React, { useEffect, useState, lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import axios from "axios";

import Courses from "../masterList/childComp/Courses";
import Course from "../masterList/childComp/Course";

const LazyCourses = lazy(() => import("../masterList/childComp/Courses"));

function MasterList() {
  const [topics, setTopics] = useState([]);
  let [OLCourseTypeOsha, setOLCourseTypeOsha] = useState([]);

  useEffect(() => {
    axios
      .get("https://safety-pros-resources.herokuapp.com/courses")
      .then((res) => {
        const initialTopics = res.data.slice(0, 15); 
        setTopics(initialTopics);

        // Load remaining items after 3 seconds
        setTimeout(() => {
          const fullTopics = res.data;
          setTopics(fullTopics);
        }, 3000);
      })
      .catch((error) => console.log(error));
  }, []);
  


  return (
    <div>
      {/* <Route exact path="/courses" render={() => <Courses topics={topics}/>} /> */}
      <Route exact 
        path="/courses" 
        render={(props) => (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyCourses {...props} topics={topics} />
          </Suspense>
        )}
      />
      <Route
        path="/courses/:id"
        render={(props) => <Course {...props} topics={topics} />}
      />
    </div>
  );
}

export default MasterList;
