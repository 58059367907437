import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from 'moment';

import noImg from "../../../../images/no.png";
import yesImg from "../../../../images/yes.png";

const Course = (props) => {
  //general
  let [CourseName, setCourseName] = useState("");
  let [TopicsCovered, setTopicsCovered] = useState("");
  let [AlternativeNames, setAlternativeNames] = useState("");
  let [TrainingDuration, setTrainingDuration] = useState("");
  let [VideoDuration, setVideoDuration] = useState("");
  let [PreOpDuration, setPreOpDuration] = useState("");
  let [Experation, setExperation] = useState("");
  let [Category, setCategory] = useState("");
  //training type
  let [OLCourseTypeOsha, setOLCourseTypeOsha] = useState("");
  let [KitCourseTypeOsha, setKitCourseTypeOsha] = useState("");
  let [TTTCourseTypeOsha, setTTTCourseTypeOsha] = useState("");
  let [VideoCourseTypeOsha, setVideoCourseTypeOsha] = useState("");
  let [PreOpCourseTypeOsha, setPreOpCourseTypeOsha] = useState("");
  let [OLCourseTypeSpan, setOLCourseTypeSpan] = useState("");
  let [KitCourseTypeSpan, setKitCourseTypeSpan] = useState("");
  let [TTTCourseTypeSpan, setTTTCourseTypeSpan] = useState("");
  let [VideoCourseTypeSpan, setVideoCourseTypeSpan] = useState("");
  let [PreOpCourseTypeSpan, setPreOpCourseTypeSpan] = useState("");
  let [OLCourseTypeCal, setOLCourseTypeCal] = useState("");
  let [KitCourseTypeCal, setKitCourseTypeCal] = useState("");
  let [TTTCourseTypeCal, setTTTCourseTypeCal] = useState("");
  let [VideoCourseTypeCal, setVideoCourseTypeCal] = useState("");
  let [PreOpCourseTypeCal, setPreOpCourseTypeCal] = useState("");
  let [OLCourseTypeCan, setOLCourseTypeCan] = useState("");
  let [KitCourseTypeCan, setKitCourseTypeCan] = useState("");
  let [TTTCourseTypeCan, setTTTCourseTypeCan] = useState("");
  let [VideoCourseTypeCan, setVideoCourseTypeCan] = useState("");
  let [PreOpCourseTypeCan, setPreOpCourseTypeCan] = useState("");
  //standards
  let [OshaStandardsList, setOshaStandardsList] = useState("");
  let [CanStandardsList, setCanStandardsList] = useState("");
  let [CalStandardsList, setCalStandardsList] = useState("");
  let [OLVersionDifferences, setOLVersionDifferences] = useState("");
  //prices
  let [OLUSPrice, setOLUSPrice] = useState("");
  let [KitUSPrice, setKitUSPrice] = useState("");
  let [TTTUSPrice, setTTTUSPrice] = useState("");
  let [VideoUSPrice, setVideoUSPrice] = useState("");
  let [PreOpUSPrice, setPreOpUSPrice] = useState("");
  let [OLSpanPrice, setOLSpanPrice] = useState("");
  let [KitSpanPrice, setKitSpanPrice] = useState("");
  let [TTTSpanPrice, setTTTSpanPrice] = useState("");
  let [VideoSpanPrice, setVideoSpanPrice] = useState("");
  let [PreOpSpanPrice, setPreOpSpanPrice] = useState("");
  let [OLCalPrice, setOLCalPrice] = useState("");
  let [KitCalPrice, setKitCalPrice] = useState("");
  let [TTTCalPrice, setTTTCalPrice] = useState("");
  let [VideoCalPrice, setVideoCalPrice] = useState("");
  let [PreOpCalPrice, setPreOpCalPrice] = useState("");
  let [OLCanPrice, setOLCanPrice] = useState("");
  let [KitCanPrice, setKitCanPrice] = useState("");
  let [TTTCanPrice, setTTTCanPrice] = useState("");
  let [VideoCanPrice, setVideoCanPrice] = useState("");
  let [PreOpCanPrice, setPreOpCanPrice] = useState("");
  //creation date
  let [OLCreationDate, setOLCreationDate] = useState("");
  let [KitCreationDate, setKitCreationDate] = useState("");
  let [TTTCreationDate, setTTTCreationDate] = useState("");
  let [VideoCreationDate, setVideoCreationDate] = useState("");
  let [PreOpCreationDate, setPreOpCreationDate] = useState("");
  //website update
  let [OLLastWebsiteUpdate, setOLLastWebsiteUpdate] = useState("");
  let [KitLastWebsiteUpdate, setKitLastWebsiteUpdate] = useState("");
  let [TTTLastWebsiteUpdate, setTTTLastWebsiteUpdate] = useState("");
  let [VideoLastWebsiteUpdate, setVideoLastWebsiteUpdate] = useState("");
  let [PreOpLastWebsiteUpdate, setPreOpLastWebsiteUpdate] = useState("");
  //LMS date
  let [OLOshaLMSUpdate, setOLOshaLMSUpdate] = useState("");
  let [TTTOshaLMSUpdate, setTTTOshaLMSUpdate] = useState("");
  let [OLSpanLMSUpdate, setOLSpanLMSUpdate] = useState("");
  let [TTTSpanLMSUpdate, setTTTSpanLMSUpdate] = useState("");
  let [OLCalLMSUpdate, setOLCalLMSUpdate] = useState("");
  let [TTTCalLMSUpdate, setTTTCalLMSUpdate] = useState("");
  let [OLCanLMSUpdate, setOLCanLMSUpdate] = useState("");
  let [TTTCanLMSUpdate, setTTTCanLMSUpdate] = useState("");
  //Last audit
  let [OLLastAudit, setOLLastAudit] = useState("");
  let [KitLastAudit, setKitLastAudit] = useState("");
  let [TTTLastAudit, setTTTLastAudit] = useState("");
  let [VideoLastAudit, setVideoLastAudit] = useState("");
  let [PreOpLastAudit, setPreOpLastAudit] = useState("");
  //last update comments
  let [OLLastUpdateChanges, setOLLastUpdateChanges] = useState("");

  useEffect(() => {
    axios
      .get(`https://safety-pros-resources.herokuapp.com/courses/${props.match.params.id}`)
      // .get("/courses", {params: {id: this.props.match.params.id} })
      .then((res) => [
        setCourseName(res.data.CourseName),
        setTopicsCovered(res.data.TopicsCovered),
        setAlternativeNames(res.data.AlternativeNames),
        setTrainingDuration(res.data.TrainingDuration),
        setVideoDuration(res.data.VideoDuration),
        setPreOpDuration(res.data.PreOpDuration),
        setExperation(res.data.Experation),
        setCategory(res.data.Category),
        //training type
        setOLCourseTypeOsha(res.data.OLCourseTypeOsha),
        setKitCourseTypeOsha(res.data.KitCourseTypeOsha),
        setTTTCourseTypeOsha(res.data.TTTCourseTypeOsha),
        setVideoCourseTypeOsha(res.data.VideoCourseTypeOsha),
        setPreOpCourseTypeOsha(res.data.PreOpCourseTypeOsha),
        setOLCourseTypeSpan(res.data.OLCourseTypeSpan),
        setKitCourseTypeSpan(res.data.KitCourseTypeSpan),
        setTTTCourseTypeSpan(res.data.TTTCourseTypeSpan),
        setVideoCourseTypeSpan(res.data.VideoCourseTypeSpan),
        setPreOpCourseTypeSpan(res.data.PreOpCourseTypeSpan),
        setOLCourseTypeCal(res.data.OLCourseTypeCal),
        setKitCourseTypeCal(res.data.KitCourseTypeCal),
        setTTTCourseTypeCal(res.data.TTTCourseTypeCal),
        setVideoCourseTypeCal(res.data.VideoCourseTypeCal),
        setPreOpCourseTypeCal(res.data.PreOpCourseTypeCal),
        setOLCourseTypeCan(res.data.OLCourseTypeCan),
        setKitCourseTypeCan(res.data.KitCourseTypeCan),
        setTTTCourseTypeCan(res.data.TTTCourseTypeCan),
        setVideoCourseTypeCan(res.data.VideoCourseTypeCan),
        setPreOpCourseTypeCan(res.data.PreOpCourseTypeCan),
        //standards
        setOshaStandardsList(res.data.OshaStandardsList),
        setCanStandardsList(res.data.CanStandardsList),
        setCalStandardsList(res.data.CalStandardsList),
        setOLVersionDifferences(res.data.OLVersionDifferences),
        //prices
        setOLUSPrice(res.data.OLUSPrice),
        setKitUSPrice(res.data.KitUSPrice),
        setTTTUSPrice(res.data.TTTUSPrice),
        setVideoUSPrice(res.data.VideoUSPrice),
        setPreOpUSPrice(res.data.PreOpUSPrice),
        setOLSpanPrice(res.data.OLSpanPrice),
        setKitSpanPrice(res.data.KitSpanPrice),
        setTTTSpanPrice(res.data.TTTSpanPrice),
        setVideoSpanPrice(res.data.VideoSpanPrice),
        setPreOpSpanPrice(res.data.PreOpSpanPrice),
        setOLCalPrice(res.data.OLCalPrice),
        setKitCalPrice(res.data.KitCalPrice),
        setTTTCalPrice(res.data.TTTCalPrice),
        setVideoCalPrice(res.data.VideoCalPrice),
        setPreOpCalPrice(res.data.PreOpCalPrice),
        setOLCanPrice(res.data.OLCanPrice),
        setKitCanPrice(res.data.KitCanPrice),
        setTTTCanPrice(res.data.TTTCanPrice),
        setVideoCanPrice(res.data.VideoCanPrice),
        setPreOpCanPrice(res.data.PreOpCanPrice),
        //creation date
        setOLCreationDate(res.data.OLCreationDate),
        setKitCreationDate(res.data.KitCreationDate),
        setTTTCreationDate(res.data.TTTCreationDate),
        setVideoCreationDate(res.data.VideoCreationDate),
        setPreOpCreationDate(res.data.PreOpCreationDate),
        //website update
        setOLLastWebsiteUpdate(res.data.OLLastWebsiteUpdate),
        setKitLastWebsiteUpdate(res.data.KitLastWebsiteUpdate),
        setTTTLastWebsiteUpdate(res.data.TTTLastWebsiteUpdate),
        setVideoLastWebsiteUpdate(res.data.VideoLastWebsiteUpdate),
        setPreOpLastWebsiteUpdate(res.data.PreOpLastWebsiteUpdate),
        //LMS date
        setOLOshaLMSUpdate(res.data.OLOshaLMSUpdate),
        setTTTOshaLMSUpdate(res.data.TTTOshaLMSUpdate),
        setOLSpanLMSUpdate(res.data.OLSpanLMSUpdate),
        setTTTSpanLMSUpdate(res.data.TTTSpanLMSUpdate),
        setOLCalLMSUpdate(res.data.OLCalLMSUpdate),
        setTTTCalLMSUpdate(res.data.TTTCalLMSUpdate),
        setOLCanLMSUpdate(res.data.OLCanLMSUpdate),
        setTTTCanLMSUpdate(res.data.TTTCanLMSUpdate),
        //Last audit
        setOLLastAudit(res.data.OLLastAudit),
        setKitLastAudit(res.data.KitLastAudit),
        setTTTLastAudit(res.data.TTTLastAudit),
        setVideoLastAudit(res.data.VideoLastAudit),
        setPreOpLastAudit(res.data.PreOpLastAudit),
        //Last update comments
        setOLLastUpdateChanges(res.data.OLLastUpdateChanges)
      ])
      .catch((error) => console.log(error));
  }, [props]);

  OLCreationDate = moment(OLCreationDate).format('MM/DD/YYYY');
  // console.log(OLCreationDate); 
  


  //Display none empty divs
  if (AlternativeNames.match("N/A")) {
    document.getElementById("AlternativeNames").style.display = "none";
  }
  if (VideoDuration.match("N/A")) {
    document.getElementById("VideoDuration").style.display = "none";
  }
  if (PreOpDuration.match("N/A")) {
    document.getElementById("PreOpDuration").style.display = "none";
  }
  if (CalStandardsList.match("N/A")) {
    document.getElementById("CalStandardsList").style.display = "none";
  }
  if (CanStandardsList.match("N/A")) {
    document.getElementById("CanStandardsList").style.display = "none";
  }
  if (OLVersionDifferences.match("N/A")) {
    document.getElementById("OLVersionDifferences").style.display = "none";
  }
  if (OLLastUpdateChanges.match("N/A")) {
    document.getElementById("OLLastUpdateChanges").style.display = "none";
  }

  //Training Types
  let trainingType = [
    OLCourseTypeOsha,
    KitCourseTypeOsha,
    TTTCourseTypeOsha,
    VideoCourseTypeOsha,
    PreOpCourseTypeOsha,
    OLCourseTypeSpan,
    KitCourseTypeSpan,
    TTTCourseTypeSpan,
    VideoCourseTypeSpan,
    PreOpCourseTypeSpan,
    OLCourseTypeCal,
    KitCourseTypeCal,
    TTTCourseTypeCal,
    VideoCourseTypeCal,
    PreOpCourseTypeCal,
    OLCourseTypeCan,
    KitCourseTypeCan,
    TTTCourseTypeCan,
    VideoCourseTypeCan,
    PreOpCourseTypeCan,
  ];
  let trainingTypeOutput = "N";
  for (let i = 0; i < trainingType.length; i++) {
    if (trainingTypeOutput === trainingType[i]) {
      trainingType[i] = <img src={noImg} alt="No icon" width="30px" />;
    } else {
      trainingType[i] = <img src={yesImg} alt="Yes icon" width="30px" />;
    }
  }

  //Prices section
  let prices = [
    OLUSPrice,
    KitUSPrice,
    TTTUSPrice,
    VideoUSPrice,
    PreOpUSPrice,
    OLSpanPrice,
    KitSpanPrice,
    TTTSpanPrice,
    VideoSpanPrice,
    PreOpSpanPrice,
    OLCalPrice,
    KitCalPrice,
    TTTCalPrice,
    VideoCalPrice,
    PreOpCalPrice,
    OLCanPrice,
    KitCanPrice,
    TTTCanPrice,
    VideoCanPrice,
    PreOpCanPrice,
  ];
  let pricesOutput = "N/A";
  for (let i = 0; i < prices.length; i++) {
    if (pricesOutput === prices[i]) {
      prices[i] = <img src={noImg} alt="No icon" width="30px" />;
    } else {
      prices[i] = prices[i];
    }
  }

  // new OLCreationDate().toLocaleDateString('en-US')
  // console.log(OLCreationDate);
  //Update dates section
  let updates = [
    OLCreationDate,
    KitCreationDate,
    TTTCreationDate,
    VideoCreationDate,
    PreOpCreationDate,
    OLLastWebsiteUpdate,
    KitLastWebsiteUpdate,
    TTTLastWebsiteUpdate,
    VideoLastWebsiteUpdate,
    PreOpLastWebsiteUpdate,
    OLOshaLMSUpdate,
    TTTOshaLMSUpdate,
    OLSpanLMSUpdate,
    TTTSpanLMSUpdate,
    OLCalLMSUpdate,
    TTTCalLMSUpdate,
    OLCanLMSUpdate,
    TTTCanLMSUpdate,
    OLLastAudit,
    KitLastAudit,
    TTTLastAudit,
    VideoLastAudit,
    PreOpLastAudit,
  ];
  let updateOutput = "N/A";
  for (let i = 0; i < updates.length; i++) {
    if (updateOutput === updates[i]) {
      updates[i] = "--";
    } else {
      updates[i] = updates[i];
    }
  }

  //import HTML
  function topicsCoveredHTML() {
    return{__html: TopicsCovered};
  }function oshaStandardsHTML() {
    return{__html: OshaStandardsList};
  }function calStandardsList() {
    return{__html: CalStandardsList};
  }function canStandardsHTML() {
    return{__html: CanStandardsList};
  }

  return (
    <div className=" grey-background">
      {/* Banner */}
      <section>
        <div className="masterBanner__banner-container">
          <h1>{CourseName}</h1>
          <h2>PRODUCT INFORMATION</h2>
        </div>
      </section>
      {/* Banner End */}
      {/* Table of contents and general information */}
      <section className="section course__general-big-flex-box">
        <div className="course__table-of-contents">
          <h3>Table of Contents</h3>
          <hr />
          <ol>
            <a href="#general-info">
              <li>
                General Info: topics covered, alternative names, duration,
                and/or expiration
              </li>
            </a>
            <a href="#training-type">
              <li>Course Type: online, kit, and/or TTT</li>
            </a>
            <a href="#standards">
              <li>
                Standards: OSHA, CAL-OSHA, and/or Canada & the diff. between
                versions
              </li>
            </a>
            <a href="#prices">
              <li>Prices</li>
            </a>
            <a href="#updates">
              <li>
                Updates: last audit, last update to the LMS, what changed, etc.
              </li>
            </a>
          </ol>
        </div>
        <div id="general-info">
          <h2 className="course__h2-blue-heading">General Information</h2>
          <hr />
          <div className="course__general-info-flex">
            <div className="course__general-info-flex-box">
              <div className="course__icon-container">
                <h3 className="course__h3-gen-heading">Topics Covered</h3>
                <div className="course__icon">
                  &#x1F6C8;
                  <span className="course__icon-hide">
                    These are the main topics discussed throughout the training
                    course.
                  </span>
                </div>
              </div>
              <div dangerouslySetInnerHTML={topicsCoveredHTML()} />
            </div>
            <div
              className="course__general-info-flex-box"
              id="AlternativeNames"
            >
              <h3 className="course__h3-gen-heading">Alternative Names</h3>
              <p>{AlternativeNames}</p>
            </div>
            <div className="course__general-info-flex-box">
              <h3 className="course__h3-gen-heading">Course Duration</h3>
              <p id="TrainingDuration">Training Courses: {TrainingDuration}</p>
              <p id="VideoDuration">Video Training: {VideoDuration}</p>
              <p id="PreOpDuration">Pre-Operation Video: {PreOpDuration}</p>
            </div>
            <div className="course__general-info-flex-box" id="Experation">
              <div className="course__icon-container">
                <h3 className="course__h3-gen-heading">Certificate Duration</h3>
                <div className="course__icon">
                  &#x1F6C8;
                  <span className="course__icon-hide">
                    This is the length of time that the certification is valid
                    following completion of the course.
                  </span>
                </div>
              </div>
              <p>{Experation}</p>
            </div>
            <div className="course__general-info-flex-box" id="Experation">
              <h3 className="course__h3-gen-heading">Training Category</h3>
              <p>{Category}</p>
            </div>
          </div>
        </div>
      </section>
      {/* General End */}
      {/* Trainig Type */}
      <br />
      <br />
      <section id="training-type" className="section">
        <h2 className="course__h2-blue-heading">Course Types</h2>
        <hr />
        <br />
        <table>
          <tr>
            <th></th>
            <th>Online Training</th>
            <th>DIY Training Kit</th>
            <th>Train the Trainer</th>
            <th>Training Video</th>
            <th>Pre-Op Video</th>
          </tr>
          <tr>
            <th>OSHA in English</th>
            <td>{trainingType[0]}</td>
            <td>{trainingType[1]}</td>
            <td>{trainingType[2]}</td>
            <td>{trainingType[3]}</td>
            <td>{trainingType[4]}</td>
          </tr>
          <tr>
            <th>OSHA in Spanish</th>
            <td>{trainingType[5]}</td>
            <td>{trainingType[6]}</td>
            <td>{trainingType[7]}</td>
            <td>{trainingType[8]}</td>
            <td>{trainingType[9]}</td>
          </tr>
          <tr>
            <th>CAL-OSHA In English</th>
            <td>{trainingType[10]}</td>
            <td>{trainingType[11]}</td>
            <td>{trainingType[12]}</td>
            <td>{trainingType[13]}</td>
            <td>{trainingType[14]}</td>
          </tr>
          <tr>
            <th>Canada In English</th>
            <td>{trainingType[15]}</td>
            <td>{trainingType[16]}</td>
            <td>{trainingType[17]}</td>
            <td>{trainingType[18]}</td>
            <td>{trainingType[19]}</td>
          </tr>
        </table>
      </section>
      {/* Training Type End */}
      {/* Standards */}
      <section className="section" id="standards">
        <h2 className="course__h2-blue-heading">Course Standards</h2>
        <hr />
        <br />
        <div className="course__standards-flex">
          <div className="course__standards-flex-box" id="OshaStandardsList">
            <h3 className="course__h3-gen-heading">U.S. Standards</h3>
            <hr />
            <div dangerouslySetInnerHTML={oshaStandardsHTML()} />
          </div>
          <div className="course__standards-flex-box" id="CalStandardsList">
            <h3 className="course__h3-gen-heading">CAL-OSHA Standards</h3>
            <hr />
            <div dangerouslySetInnerHTML={calStandardsList()} />
          </div>
          <div className="course__standards-flex-box" id="CanStandardsList">
            <h3 className="course__h3-gen-heading">Canada Standards</h3>
            <hr />
            <div dangerouslySetInnerHTML={canStandardsHTML()} />
          </div>
        </div>
        <br />
        <div id="OLVersionDifferences">
          <div className="course__icon-container">
            <h3 className="course__h3-gen-heading">
            Difference between versions
          </h3>
          <div className="course__icon">
            &#x1F6C8;
            <span className="course__icon-hide">
              This refers to changes in content between different versions of
              the training course, usually due to differences in the safety
              standards they cover.
            </span>
          </div>
          </div>
          
          <p>
          <span>{OLVersionDifferences}</span>
          {/* <span id="KitVersionDifferences"> CAL-OSHA Differnces: {KitVersionDifferences}</span> 
          <span id="TTTVersionDifferences"> TTT Differnces: {TTTVersionDifferences}</span>  */}
        </p>
        </div>
        
      </section>
      {/* Standards End */}
      {/* Prices */}
      <section className="section" id="prices">
        <h2 className="course__h2-blue-heading">Course Prices</h2>
        <hr />
        <br />
        <div>
          <table>
            <tr>
              <th></th>
              <th>Online Training</th>
              <th>DIY Training Kit</th>
              <th>Train the Trainer</th>
              <th>Training Video</th>
              <th>Pre-Op Video</th>
            </tr>
            <tr>
              <th>OSHA in English</th>
              <td>{prices[0]}</td>
              <td>{prices[1]}</td>
              <td>{prices[2]}</td>
              <td>{prices[3]}</td>
              <td>{prices[4]}</td>
            </tr>
            <tr>
              <th>OSHA in Spanish</th>
              <td>{prices[5]}</td>
              <td>{prices[6]}</td>
              <td>{prices[7]}</td>
              <td>{prices[8]}</td>
              <td>{prices[9]}</td>
            </tr>
            <tr>
              <th>CAL-OSHA In English</th>
              <td>{prices[10]}</td>
              <td>{prices[11]}</td>
              <td>{prices[12]}</td>
              <td>{prices[13]}</td>
              <td>{prices[14]}</td>
            </tr>
            <tr>
              <th>Canada In English</th>
              <td>{prices[15]}</td>
              <td>{prices[16]}</td>
              <td>{prices[17]}</td>
              <td>{prices[18]}</td>
              <td>{prices[19]}</td>
            </tr>
          </table>
        </div>
      </section>
      {/* Price End */}
      {/* Updates */}
      <section className="section" id="updates">
        <h2 className="course__h2-blue-heading">Updates</h2>
        <hr />
        <br />
        <div>
          <div className="course__icon-container">
            <h3 className="course__h3-gen-heading">Creation Date</h3>
            <div className="course__icon">
              &#x1F6C8;
              <span className="course__icon-hide">
                This refers to the date the training course was first published.
              </span>
            </div>
          </div>
          <table>
            <tr>
              <th>Online Training Date</th>
              <th>DIY Training Kit Date</th>
              <th>Train the Trainer Date</th>
              <th>Training Video Date</th>
              <th>Pre-Op Video Date</th>
            </tr>
            <tr>
              <td>{updates[0]}</td>
              <td>{updates[1]}</td>
              <td>{updates[2]}</td>
              <td>{updates[3]}</td>
              <td>{updates[4]}</td>
            </tr>
          </table>
        </div>
        <br />
        <div>
          <div className="course__icon-container">
            <h3 className="course__h3-gen-heading">Last Website Update</h3>
            <div className="course__icon">
              &#x1F6C8;
              <span className="course__icon-hide">
                This refers to the date the training course was last updated on
                the Hard Hat Training website.
              </span>
            </div>
          </div>
          <table>
            <tr>
              <th>Online Training Date</th>
              <th>DIY Training Kit Date</th>
              <th>Train the Trainer Date</th>
              <th>Training Video Date</th>
              <th>Pre-Op Video Date</th>
            </tr>
            <tr>
              <td>{updates[5]}</td>
              <td>{updates[6]}</td>
              <td>{updates[7]}</td>
              <td>{updates[8]}</td>
              <td>{updates[9]}</td>
            </tr>
          </table>
        </div>
        <br />
        <div>
          <div className="course__icon-container">
            <h3 className="course__h3-gen-heading">Last LMS Update</h3>
            <div className="course__icon">
              &#x1F6C8;
              <span className="course__icon-hide">
                Entries in this table mark the last time any updates or changes
                were made to the online version of each training course.
              </span>
            </div>
          </div>
          <table>
            <tr>
              <th></th>
              <th>Online Training Date</th>
              <th>Train the Trainer Date</th>
            </tr>
            <tr>
              <th>OSHA in English</th>
              <td>{updates[10]}</td>
              <td>{updates[11]}</td>
            </tr>
            <tr>
              <th>OSHA in Spanish</th>
              <td>{updates[12]}</td>
              <td>{updates[13]}</td>
            </tr>
            <tr>
              <th>CAL-OSHA In English</th>
              <td>{updates[14]}</td>
              <td>{updates[15]}</td>
            </tr>
            <tr>
              <th>Canada In English</th>
              <td>{updates[16]}</td>
              <td>{updates[17]}</td>
            </tr>
          </table>
        </div>
        <br />
        <div>
          <div className="course__icon-container">
            <h3 className="course__h3-gen-heading">Last Audit Date</h3>
            <div className="course__icon">
              &#x1F6C8;
              <span className="course__icon-hide">
                This refers to the last date when an evaluation audit was
                preformed on the course.
              </span>
            </div>
          </div>
          <table>
            <tr>
              <th>Online Training Date</th>
              <th>DIY Training Kit Date</th>
              <th>Train the Trainer Date</th>
              <th>Training Video Date</th>
              <th>Pre-Op Video Date</th>
            </tr>
            <tr>
              <td>{updates[18]}</td>
              <td>{updates[19]}</td>
              <td>{updates[20]}</td>
              <td>{updates[21]}</td>
              <td>{updates[22]}</td>
            </tr>
          </table>
        </div>
        <br />
        <div className="course__icon-container">
          <h3 className="course__h3-gen-heading">Last Update Changes</h3>
          <div className="course__icon">
            &#x1F6C8;
            <span className="course__icon-hide">
              This comment details what was changed in the last update.
            </span>
          </div>
        </div>
        <p>
          <p id="OLLastUpdateChanges">
            {OLLastUpdateChanges}
          </p>
          
        </p>
      </section>
      <section className="section"></section>
    </div>
  );
};

export default Course;
