import React from "react";

function EmployeePortal() {
    
    return (
      <section className="master__section section">
        <h1>Coming Soon!</h1>
      </section>
    );
}

export default EmployeePortal;